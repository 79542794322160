import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { flatten } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useNotificationContext } from "state/context";
import { Form, FormActions } from "components/forms";
import { EmployeesApi, usePayrollList, useTroncSchemesList } from "lib/api-endpoints";
import { downloadBlob } from "lib/downloaders/stream-file-handler";
import ImportEmployeeFormContent from "./import-employee-form-content";
import CsvLineErrorValidation from "components/csv/csv-line-error-validation";
import { LoadingMask, PrimaryButton } from "components";

import styles from './import-employees-form.module.scss';

/**
 * Downloads the employee CSV template for a given payroll
 *
 * @param {number} payroll The payroll id
 * @param {HTMLElement} targetNode The target node to place temporary click handler
 *
 * @return {void} Downloads template
 */
const handleTemplateDownload = ({ payroll, targetNode }) => {
    const { request } = EmployeesApi.getImportCSVTemplate({ "payroll_id": payroll });

    downloadBlob(request, {
        filename: 'employees-import-template',
        targetNode,
    });
};

/**
 * Gets the import request function
 *
 * @param {function} setCSVErrors Handle displaying CSV errors
 * @param {function} openNotification Open any notifications
 * @param {function} onComplete Callback for import completion
 * @param {function} onClose The close callback
 * @param {function} reset The form reset function
 * @param {function} setLoading The drawer loading state
 *
 * @returns {function} The import request function
 */
const handleImport = ({ setCSVErrors, openNotification, onComplete, onClose, reset, setLoading }) => {
    return async (formData) => {
        setLoading(true);

        await EmployeesApi.uploadEmployees({
            payroll: formData.payroll,
            employees: formData.employees[0],
            scheme: formData.scheme,
        }).request
            .then(({ data }) => {
                if (data?.status === 'success') {
                    const { total_created: totals } = data;

                    onComplete();
                    onClose();
                    reset();

                    openNotification({
                        message: `Imported ${totals.employees} employees successfully`,
                        type: "success",
                    });
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    setCSVErrors(flatten(error.response.data.errors));
                }
            });
    };
};

/**
 * Renders the import employees form
 *
 * @param {object} defaultValues The default values of the form
 * @param {function} onClose The close callback
 * @param {object} company The payrolls available
 * @param {function} onComplete The complete import callback
 * @param {function} setLoading Updates the loading state of the drawer
 * @param {boolean} isLoading Drawer loading state
 *
 * @returns {React.Component} Renders the import employees form
 */
const ImportEmployeesForm = ({ company, onClose, onComplete, setLoading, isLoading }) => {
    const [csvErrors, setCSVErrors] = useState([]);
    const { openNotification } = useNotificationContext();
    const importFormRef = useRef(null);
    const { payrolls, isLoading: payrollsLoading } = usePayrollList({ companyId: company.id });
    const { schemes, isLoaded: schemesLoaded } = useTroncSchemesList({ companyIds: [company.id] });
    const methods = useForm({ mode: "onChange" });
    const values = methods.getValues();
    methods.watch();

    useEffect(() => {
        const loadingComplete = payrollsLoading && !schemesLoaded;

        if (loadingComplete) {
            setLoading(false);
        }
    }, [payrollsLoading, schemesLoaded, setLoading]);

    return (
        <FormProvider {...methods}>
            {
                isLoading ? (
                    <LoadingMask loading={isLoading} />
                )
                    : (
                        <Form
                            ref={importFormRef}
                            onSubmit={methods.handleSubmit(handleImport({
                                setLoading,
                                setCSVErrors,
                                openNotification,
                                onComplete,
                                onClose,
                                reset: methods.reset,
                            }))}
                        >
                            <div className={styles.formContentWrapper}>
                                <PrimaryButton
                                    onClick={() => handleTemplateDownload({
                                        payroll: values.payroll,
                                        targetNode: importFormRef.current,
                                    })}
                                    disabled={!values.payroll}
                                    className={styles.downloadTemplate}
                                >
                                    Download CSV Template
                                </PrimaryButton>
                                <CsvLineErrorValidation errors={csvErrors} />
                                <ImportEmployeeFormContent payrolls={payrolls} schemes={schemes} />
                            </div>
                            <FormActions
                                submitText="Import"
                                disableSubmit={(!(values.payroll && values.employees?.length === 1))}
                                onCancel={onClose}
                            />
                        </Form>
                    )}
        </FormProvider>
    );
};

ImportEmployeesForm.propTypes = {
    company: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default ImportEmployeesForm;
