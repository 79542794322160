import React, { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Drawer, DrawerTitle } from "components";
import ImportEmployeesForm from "./import-employees-form";
import companyPropTypes from "lib/props-schema/company-schema";

/**
 * Renders the import employees drawer
 *
 * @param {object} company The selected company
 * @param {function} setDrawer Handle the drawer state
 * @param {boolean} isDrawerOpen Determines if drawer is open or closed
 * @param {function} onComplete The callback once the import is complete
 *
 * @returns {React.Component} Renders the import employees drawer
 */
const ImportEmployeesDrawer = ({ isDrawerOpen, setDrawer, company, onComplete }) => {
    const [isLoading, setLoading] = useState(true);

    const closeDrawer = () => {
        setDrawer(false);
        setLoading(true);
    };

    return (
        <Drawer
            isOpen={isDrawerOpen}
            isLoading={isLoading}
            headerContent={<DrawerTitle>Import Employees</DrawerTitle>}
            onClose={closeDrawer}
            onOutsideClick={closeDrawer}
            isAsync
            padding={false}
            ariaLabel="import employee drawer"
        >
            <ImportEmployeesForm
                onClose={closeDrawer}
                onComplete={onComplete}
                company={company}
                setLoading={setLoading}
                isLoading={isLoading}
            />
        </Drawer>
    );
};

ImportEmployeesDrawer.propTypes = {
    setDrawer: PropTypes.func.isRequired,
    isDrawerOpen: PropTypes.bool.isRequired,
    company: companyPropTypes.isRequired,
    onComplete: PropTypes.func,
};

ImportEmployeesDrawer.defaultProps = {
    onComplete: noop,
};

export default ImportEmployeesDrawer;
