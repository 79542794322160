import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TroncSchemesApi } from "lib/api-endpoints";

const useTroncPoints = (employee, currentTroncScheme) => {
    const [points, setPoints] = useState({
        jobRole: undefined,
        individual: undefined,
        LOS: undefined,
        training: undefined,
    });

    const [isLoaded, setIsLoaded] = useState(false);

    const getPoints = useCallback(
        (type) => (currentTroncScheme && type ? parseInt(type, 10) : 0),
        [currentTroncScheme]
    );

    useEffect(() => {
        if (employee && currentTroncScheme?.length > 0) {
            TroncSchemesApi.getEmployeeTroncPoints(currentTroncScheme[0].id, employee.id)
                .request
                .then(({ data }) => {
                    setPoints({
                        jobRole: getPoints(data?.job_role?.[0]?.points),
                        individual: getPoints(data?.individual),
                        LOS: getPoints(data?.length_of_service),
                        training: getPoints(data?.training),
                    });

                    setIsLoaded(true);
                });
        } else {
            setIsLoaded(true);
        }
    }, [employee, currentTroncScheme, getPoints]);

    return {points, setPoints, isLoaded};
};

useTroncPoints.propTypes = {
    employee: PropTypes.object.isRequired,
    currentTroncScheme: PropTypes.array.isRequired,
};

export default useTroncPoints;
